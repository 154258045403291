<template>
    <BasicModal
        :show="addWeatherStationModalOpen"
        @close="onAddCancel"
    >
        <AddWeatherStationForm
            :onCancel="onAddCancel"
            :onSave="onAddSave"
            :isBusy="isBusy"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Weather Station"
        confirmationText="Are you sure you want to delete this weather station?"
        :open="confirmDeleteModalOpen"
        @delete="onDeleteWeatherStation"
        @cancel="onConfirmDeleteCancel"
        @close="onConfirmDeleteCancel"
    />
    <DataManagerContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        View, add, and remove weather stations.
                    </PageDescription>
                    <ModalButton
                        v-if="isDataAnalyst === false"
                        theme="primary"
                        label="Add Weather Station"
                        :busy="isBusy"
                        @click.prevent="onShowAdd"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isBusy">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="paginationSize"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        :getRowNodeId="useWeatherStationAsRowNodeId"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                    >
                    </AgGridVue>
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </DataManagerContainer>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, ref, onMounted } from 'vue';
    import router from '@/router';
    import {
        fetchWeatherStations,
        registerWeatherStation,
        unregisterWeatherStation,
    } from '@/api/accounts/weather';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import BasicModal from '@/components/BasicModal.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import DataManagerContainer from '~DataManager/components/wrappers/DataManagerContainer.vue';
    import AddWeatherStationForm from '~DataManager/components/form/AddWeatherStationForm.vue';
    import WeatherStationsTableIcons from '~DataManager/components/cell/WeatherStationsTableIcons.vue';

    // <!-- UTILITIES -->
    import { capitalFormatter } from '@/utils/CapitalFormatter';
    import { DynamicEnumFactory } from '@/utils/DynamicEnum';

    // <!-- Composables -->
    import useAgGridVue from '@/hooks/useAgGridVue';
    import { useWeatherStationIndex } from '@/hooks/cache/useWeatherStationIndex';

    // <!-- TYPES -->

    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/models/weather/WeatherStation').WeatherStationResource} WeatherStationResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'WeatherStations',
        components: {
            AgGridVue,
            PageWrapper,
            PageIntro,
            PageDescription,
            DataManagerContainer,
            LoadingWrapper,
            ModalButton,
            BasicModal,
            AddWeatherStationForm,
            ConfirmDeleteModal,
        },
        setup() {
            // STORE
            const { isFetching, cache, stations, refreshWeatherStationIndex } =
                useWeatherStationIndex();
            const store = cache.api.store;

            // STATE
            /** @type {V.Ref<Boolean>} */
            const refreshing = ref(false);
            /** @type {V.Ref<Boolean>} */
            const saving = ref(false);
            /** @type {V.Ref<Boolean>} */
            const addWeatherStationModalOpen = ref(false);
            /** @type {V.Ref<Boolean>} */
            const confirmDeleteModalOpen = ref(false);
            /** @type {V.Ref<WeatherStationResource>} */
            const targetWeatherStation = ref(null);
            /** @type {V.Ref<Number>} */
            const paginationSize = ref(25);

            const user = computed(() => {
                return store.state.users.me;
            });

            /** @type {V.ComputedRef<Boolean>} */
            const isDataAnalyst = computed(
                () => user.value?.role === 'data-analyst'
            );

            // PROPERTIES

            /** @type {V.Ref<WeatherStationResource[]>} */
            const weatherStations = computed(() => {
                const indexedStations = stations.value;
                return indexedStations.length <= 0 ? [] : indexedStations;
            });

            /** Column field keys. */
            const colFields = DynamicEnumFactory().fromKeys(
                /** @type {const} */ (['id', 'name', 'state', 'country'])
            );

            /** @type {Record<typeof colFields[keyof colFields], AgGrid.ColumnDef>} Column schema. */
            const colSchema = {
                [colFields.id]: {
                    headerName: '',
                    field: colFields.id,
                    cellRendererFramework: WeatherStationsTableIcons,
                    lockPosition: true,
                    filter: false,
                    maxWidth: 100,
                    cellRendererParams: {
                        handleAnalysis: (event, id) => {
                            //TODO: This route URL may change?
                            //TODO: Format should be standarized with route query params.
                            router.push(`/analysis?station=${id}`);
                        },
                        handleDelete: (event, id) => {
                            targetWeatherStation.value =
                                weatherStations.value.find(
                                    (station) => station.id == id
                                );
                            confirmDeleteModalOpen.value = true;
                        },
                    },
                },
                [colFields.name]: {
                    headerName: 'Weather Station',
                    field: colFields.name,
                    maxWidth: 500,
                    sort: 'asc',
                    valueFormatter: (params) => {
                        return capitalFormatter(params.value);
                    },
                },
                [colFields.state]: {
                    headerName: 'State',
                    field: colFields.state,
                    maxWidth: 100,
                    valueFormatter: (params) => {
                        return params.value.toLocaleUpperCase();
                    },
                },
                [colFields.country]: {
                    headerName: 'Country',
                    field: 'country',
                },
            };

            /** @type {AgGrid.ColumnDef[]} Ordered column definitions. */
            const colDefs = [
                colSchema.id,
                colSchema.name,
                colSchema.state,
                colSchema.country,
            ];

            /** @type {V.ComputedRef<Omit<WeatherStationResource, 'serial'>[]>} Row data made up of location resources. */
            const rowData = computed(() => {
                const exists = !!weatherStations.value;
                /** @type {Omit<WeatherStationResource, 'serial'>[]} */
                const rows = !exists
                    ? []
                    : weatherStations.value.map((station) => {
                          const [city, state] = station.name.split(',');
                          return {
                              id: station.id,
                              name: city.trim(),
                              state: state.trim(),
                              country: 'United States',
                          };
                      });
                return rows;
            });

            /**
             * @type {V.ComputedRef<Boolean>} Is the table refreshing?
             */
            const isRefreshing = computed(
                () => refreshing.value || isFetching.value
            );

            /**
             * @type {V.ComputedRef<Boolean>} Is the form saving?
             */
            const isSaving = computed(() => saving.value);

            /**
             * @type {V.ComputedRef<Boolean>} Is the page busy?
             */
            const isBusy = computed(() => isRefreshing.value || isSaving.value);

            // COMPOSABLES

            const defaultColDef = Object.freeze({
                resizable: true,
                sortable: true,
                filter: true,
                floatingFilter: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                suppressMovable: true,
                suppressMenu: true,
                lockPosition: true,
                minWidth: 150,
                flex: 1,
                cellClass: 'flex justify-center items-center',
            });

            const { onGridReady, onColumnResized } = useAgGridVue();

            /** @type {() => AccountResource} */
            const getAccount = () => store.state.accounts.account;

            /**
             * Fetch the weather stations.
             * @param {Boolean} [forceReload]
             */
            const requestWeatherStations = async (forceReload = false) => {
                try {
                    refreshing.value = true;
                    await refreshWeatherStationIndex(forceReload);
                } catch (err) {
                    console.error(err);
                    console.log('Failed to load weather stations!');
                } finally {
                    refreshing.value = false;
                }
            };

            const useWeatherStationAsRowNodeId = (data) => {
                return data.id;
            };

            const onShowAdd = () => {
                targetWeatherStation.value = null;
                addWeatherStationModalOpen.value = true;
            };

            const onAddCancel = () => {
                targetWeatherStation.value = null;
                addWeatherStationModalOpen.value = false;
            };

            const onAddSave = async (newStation) => {
                addWeatherStationModalOpen.value = false;
                saving.value = true;
                const request = { id: newStation.stationId };

                // Register station.
                try {
                    const response = await registerWeatherStation(
                        getAccount(),
                        request
                    );
                } catch (err) {
                    console.error('Error creating weather station', err);
                } finally {
                    saving.value = false;
                }

                // Refresh weather stations.
                await requestWeatherStations(true);
            };

            const onConfirmDeleteCancel = () => {
                confirmDeleteModalOpen.value = false;
            };

            const onDeleteWeatherStation = async () => {
                confirmDeleteModalOpen.value = false;
                saving.value = true;
                try {
                    const response = await unregisterWeatherStation(
                        getAccount(),
                        targetWeatherStation.value
                    );
                    console.warn(
                        `Soft deleted weather station '${targetWeatherStation.value.name}'.`
                    );
                } catch (err) {
                    console.error('Error creating weather station', err);
                } finally {
                    saving.value = false;
                    targetWeatherStation.value = null;
                }

                // Refresh stations.
                await requestWeatherStations(true);
            };

            /** Lifecycle Methods */
            onMounted(async () => {
                await requestWeatherStations(false);
            });

            return {
                isRefreshing,
                isSaving,
                isBusy,
                addWeatherStationModalOpen,
                confirmDeleteModalOpen,
                weatherStations,
                targetWeatherStation,
                rowData,
                colFields,
                colSchema,
                colDefs,
                defaultColDef,
                paginationSize,
                requestWeatherStations,
                onGridReady,
                onColumnResized,
                onShowAdd,
                onAddCancel,
                onAddSave,
                useWeatherStationAsRowNodeId,
                onConfirmDeleteCancel,
                onDeleteWeatherStation,

                user,
                isDataAnalyst,
            };
        },
    });
</script>

<style lang="scss"></style>
