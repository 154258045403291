// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- MODELS -->
import { Weather } from '@/models';

// <!-- ROUTES -->
const ROUTES = {
    LIST_STATES: () => 'weather/states', // Deprecated.
    LIST_STATIONS: (state) => `weather/states/${state}`, // Deprecated.
    STATES: () => `weather/states`,
    COUNTRIES: () => `weather/countries`,
    STATIONS: {
        INDEX: () => `weather/stations`,
        BY_ID: (station = -1) => `weather/stations/${station}`,
        BY_STATE: (state = 'NY') => `weather/states/${state}/stations`,
        BY_COUNTRY: (country = 'US') => `weather/countries/${country}/stations`,
    },
};

// <!-- REQUESTS -->
/**
 * Fetch available states in the weather database.
 * @returns {Promise<import('@/models/weather/WeatherState').WeatherStateResource[]>} Collection of Weather.WeatherStateResources.
 * @example
 * import { fetchWeatherStates } from "@api/weather";
 * const states = await fetchWeatherStates(); // => ...Weather.WeatherStateResource[];
 */
export const fetchWeatherStates = async () => {
    /** @type {import('axios').AxiosResponse<{ data: import('@/models/weather/WeatherState').WeatherStatePayload[] }>} */
    const response = await axios().get(ROUTES.STATES());
    const collection = response.data.data;
    return collection.map((state) =>
        new Weather.WeatherState({ payload: state }).toResource()
    );
};

/**
 * Fetch weather stations for the specified state.
 * @param {Pick<import('@/models/weather/WeatherState').WeatherStateResource, 'id'>} state
 * @returns {Promise<import('@/models/weather/WeatherStation').WeatherStationResource[]>} Collection of Weather.WeatherStationResources.
 * @example
 * import { fetchWeatherStationsByState } from "@api/weather";
 * const states = await fetchWeatherStationsById({ id: "NY" }); // => ...Weather.WeatherStationResource[];
 */
export const fetchWeatherStationsByState = async (state) => {
    /** @type {import('axios').AxiosResponse<{ data: import('@/models/weather/WeatherStation').WeatherStationPayload[] }>} */
    const response = await axios().get(ROUTES.STATIONS.BY_STATE(state?.id));
    const collection = response.data.data;
    return collection.map((station) =>
        new Weather.WeatherStation({ payload: station }).toResource()
    );
};

// <!-- EXPORTS -->
export default {
    WeatherState: Weather.WeatherState,
    WeatherStation: Weather.WeatherStation,
    fetchWeatherStates,
    fetchWeatherStationsByState,
};
