<template>
    <!-- TODO: Refactor AddLocationForm to be like Note CRUD. -->
    <BasicModal
        size="lg"
        :show="confirmAddModal.isOpen"
        @close="onAddCancel"
    >
        <AddLocationForm
            :form="form"
            :isBusy="isBusy || !isEditing"
        />
    </BasicModal>
    <ConfirmDeleteModal
        title="Delete Location"
        confirmationText="Are you sure you want to delete this location?  This action cannot be undone."
        :open="confirmDeleteModal.isOpen"
        @delete="onDeleteLocation"
        @cancel="onConfirmDeleteCancel"
        @close="onConfirmDeleteCancel"
    />

    <DataManagerContainer>
        <template #mainContent>
            <PageWrapper>
                <PageIntro>
                    <PageDescription>
                        View, add, and remove locations.
                    </PageDescription>
                    <ModalButton
                        v-if="isDataAnalyst === false"
                        theme="primary"
                        label="Add Location"
                        :busy="isLoading || isBusy"
                        @click="onShowAdd"
                    />
                </PageIntro>
                <LoadingWrapper :isLoading="isLoading || isSaving">
                    <AgGridVue
                        class="ag-theme-alpine"
                        domLayout="autoHeight"
                        :pagination="true"
                        :paginationPageSize="paginationSize"
                        :columnDefs="colDefs"
                        :rowData="rowData"
                        :rowHeight="null"
                        :defaultColDef="defaultColDef"
                        :getRowNodeId="useLocationAsRowNodeId"
                        @grid-ready="onGridReady"
                        @column-resized="onColumnResized"
                        overlayNoRowsTemplate="No locations to display."
                    ></AgGridVue>
                </LoadingWrapper>
            </PageWrapper>
        </template>
    </DataManagerContainer>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, ref, onBeforeMount } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';

    // <!-- TYPES -->
    /** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
    /** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

    // <!-- UTILITIES -->
    import isNil from 'lodash-es/isNil';
    import { formatISO } from 'date-fns';
    import { DateTimeISO } from '@/utils/datetime';
    import { DynamicEnumFactory } from '@/utils/DynamicEnum';

    // <!-- COMPONENTS -->
    import BasicModal from '@/components/BasicModal.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@components/PageWrapper.vue';
    import PageDescription from '@/components/PageDescription.vue';
    import PageIntro from '@components/PageIntro.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import DataManagerContainer from '~DataManager/components/wrappers/DataManagerContainer.vue';
    import LocationTableIcons from '~DataManager/components/cell/LocationTableIcons.vue';
    import AddLocationForm from '~DataManager/components/form/AddLocationForm.vue';
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import useAgGridVue from '@/hooks/useAgGridVue';
    import {
        LocationFormProps,
        useLocationForm,
    } from '~DataManager/hooks/useLocationForm';
    import { useLocationIndex } from '@/hooks/cache/useLocationIndex';
    import { useNARAStandardsData } from '../hooks/useNARAStandardOptions';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DataManager',
        components: {
            PageWrapper,
            PageDescription,
            PageIntro,
            AgGridVue,
            ModalButton,
            BasicModal,
            AddLocationForm,
            LoadingWrapper,
            ConfirmDeleteModal,
            DataManagerContainer,
        },
        setup(props, context) {
            // PROPS (FORM)
            const formProps = new LocationFormProps(props);
            formProps.onInit = async () => {
                targetLocation.value = null;
                form.methods.closeFormModals();
            };
            formProps.onShowAdd = async () => {
                targetLocation.value = null;
            };
            formProps.onAddCancel = async () => {
                targetLocation.value = null;
            };
            formProps.onSave = async () => {
                await refreshLocationIndex(true);
            };
            formProps.onCreate = async () => {
                form.methods.closeModal(confirmAddModal);
                targetLocation.value = null;
                await refreshLocationIndex(true);
            };
            formProps.onDelete = async () => {
                form.methods.closeModal(confirmDeleteModal);
                console.warn(
                    `Soft deleted Location '${targetLocation.value.name} [${targetLocation.value.id}]'.`
                );
                targetLocation.value = null;
                await refreshLocationIndex(true);
            };
            const form = useLocationForm(formProps, context);

            // STATE (FORM)
            const { isFetching, cache, locations, refreshLocationIndex } =
                useLocationIndex(form.cache);

            // STATE
            const {
                cleanLocationDetails,
                dirtyLocationDetails,
                confirmAddModal,
                confirmDeleteModal,
                targetLocation,
            } = form.state;

            const user = computed(() => {
                return store.state.users.me;
            });

            /** @type {V.ComputedRef<Boolean>} */
            const isDataAnalyst = computed(
                () => user.value?.role === 'data-analyst'
            );

            // STATUS (FORM)
            const { isBusy, isDeleting, isEditing, isRefreshing, isSaving } =
                form.properties;

            // EVENT HANDLERS
            const {
                onReset,

                onShowConfirmDelete,
                onConfirmDeleteCancel,
                onDelete: onDeleteLocation,

                onShowAdd,
                onAddCancel,
            } = form.handlers;

            /**
             * Format the ISO string into just its date component.
             * @param {{ value: any }} params
             */
            const useDateComponentFormat = (params) => {
                const value = params.value;
                if (!isNil(value) && value !== '') {
                    const date = DateTimeISO.parse(value);
                    const formatted = formatISO(date, {
                        format: 'extended',
                        representation: 'date',
                    });
                    return formatted;
                }
                return 'No Date Provided';
            };

            // STATE (INDEX)
            /** @type {V.Ref<Number>} */
            const paginationSize = ref(25);

            // PROPERTIES (INDEX)

            /**
             * @type {V.ComputedRef<Boolean>}
             */
            const isLoading = computed(() => {
                return (
                    cache.notes.value.has.status('fetching') ||
                    cache.notes.value.has.status('caching') ||
                    isFetching.value
                );
            });

            /**
             * @type {V.ComputedRef<LocationResource[]>}
             */
            const accountLocations = computed(() => locations.value);

            /** Column field keys. */
            const colFields = DynamicEnumFactory().fromKeys(
                /** @type {const} */ ([
                    'id',
                    'name',
                    'path',
                    'maxDate',
                    'lastUploadDate',
                    'dataLoggerManufacturer',
                    'dataLoggerSerialNumber',
                    'dateUpdated',
                ])
            );

            /** @type {Record<typeof colFields[keyof colFields], AgGrid.ColumnDef>} Column schema. */
            const colSchema = {
                [colFields.id]: {
                    headerName: '',
                    field: colFields.id,
                    cellRendererFramework: LocationTableIcons,
                    lockPosition: true,
                    resizable: true,
                    minWidth: 105,
                    maxWidth: 115,
                    filter: false,
                    cellRendererParams: {
                        handleView: (event, id) => {
                            changeRoute(id);
                        },
                        handleAnalysis: (event, id) => {
                            //FIXME: This route URL may change?
                            router.push(`/analysis?location=${id}`);
                        },
                        handleDelete: (event, id) => {
                            const target = accountLocations.value.find(
                                (location) => location.id == id
                            );
                            const { id: location, name } = target ?? {
                                id: null,
                                name: null,
                            };
                            if (!!location) {
                                targetLocation.value = {
                                    id: location,
                                    name: name,
                                };
                                onShowConfirmDelete();
                            } else {
                                console.error(
                                    'Failed to select matching location.'
                                );
                            }
                        },
                    },
                },
                [colFields.name]: {
                    headerName: 'Location Name',
                    field: colFields.name,
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? params.value
                            : 'No name provided.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.name,
                    },
                    wrapText: true,
                    autoHeight: true,
                    minWidth: 310,
                    // sort: 'asc',
                },
                [colFields.path]: {
                    headerName: 'Location Hierarchy',
                    field: colFields.path,
                    cellRenderer: (params) => {
                        const segments =
                            !!params?.value && params.value !== ''
                                ? params.value.split('/')
                                : getAccountHierarchyLabels();
                        return segments.join(' / ');
                    },
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.path,
                    },
                    wrapText: true,
                    autoHeight: true,
                    minWidth: 330,
                    sort: 'asc',
                },
                [colFields.maxDate]: {
                    headerName: 'Data End',
                    field: 'maxDate',
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? useDateComponentFormat(params)
                            : 'No datasets.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.maxDate,
                    },
                    filter: false,
                    // minWidth: 130,
                },
                [colFields.lastUploadDate]: {
                    headerName: 'Last Upload Date',
                    field: 'lastUploadDate',
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? useDateComponentFormat(params)
                            : 'No datasets.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.maxDate,
                    },
                    filter: false,
                    minWidth: 80,
                },
                [colFields.dataLoggerManufacturer]: {
                    headerName: 'Datalogger Mfr.',
                    field: colFields.dataLoggerManufacturer,
                    minWidth: 200,
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? params.value
                            : 'No manufacturer provided.',
                    cellClassRules: {
                        'text-gray-400': (params) =>
                            !params.data?.dataLoggerManufacturer,
                    },
                },
                [colFields.dataLoggerSerialNumber]: {
                    headerName: 'Datalogger Serial',
                    field: colFields.dataLoggerSerialNumber,
                    maxWidth: 180,
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? params.value
                            : 'No serial number.',
                    cellClassRules: {
                        'text-gray-400': (params) =>
                            !params.data?.dataLoggerSerialNumber,
                    },
                },
                [colFields.dateUpdated]: {
                    headerName: 'Last Updated',
                    field: 'dateUpdated',
                    cellRenderer: (params) =>
                        !!params.value && params.value !== ''
                            ? useDateComponentFormat(params)
                            : 'No datasets.',
                    cellClassRules: {
                        'text-gray-400': (params) => !params.data?.dateUpdated,
                    },
                    filter: false,
                    // minWidth: 130,
                },
            };

            /** @type {AgGrid.ColumnDef[]} Ordered column definitions. */
            const colDefs = [
                colSchema.id,
                colSchema.path,
                colSchema.name,
                colSchema.dataLoggerSerialNumber,
                colSchema.maxDate,
                colSchema.dateUpdated,
            ];

            /** @type {V.ComputedRef<LocationResource[]>} Row data made up of location resources. */
            const rowData = computed(() => {
                const indexedLocations = accountLocations.value;
                return indexedLocations.length <= 0 ? [] : indexedLocations;
            });

            // METHODS (INDEX)

            /* Functions */
            const { onGridReady, onColumnResized, defaultColDef } =
                useAgGridVue();

            /** @type {() => AccountResource} */
            const getAccount = () => cache.api.store.state.accounts.account;

            const getAccountHierarchyLabels = () => {
                const defaultLabels = ['Site', 'Building', 'Floor', 'Room'];
                const accountLabels = getAccount()?.accountTreeLevel ?? null;
                return accountLabels ?? defaultLabels;
            };

            const useLocationAsRowNodeId = (data) => {
                return data.id;
            };

            const changeRoute = (id) => {
                router.push(`/data-manager/locations/${id}`);
            };

            const store = useStore();
            const { fetchData: fetchNARAStandardData } =
                useNARAStandardsData(store);

            /* Lifecycle methods */
            onBeforeMount(async () => {
                await refreshLocationIndex(true);
                await fetchNARAStandardData();
            });

            return {
                form,
                accountLocations,
                targetLocation,

                isLoading,
                isBusy,
                isDeleting,
                isEditing,
                isRefreshing,
                isSaving,

                cleanLocationDetails,
                dirtyLocationDetails,

                confirmAddModal,
                confirmDeleteModal,

                rowData,
                colDefs,
                defaultColDef,
                paginationSize,

                refreshLocationIndex,
                onGridReady,
                onColumnResized,
                useLocationAsRowNodeId,

                onShowAdd,
                onAddCancel,

                onShowConfirmDelete,
                onConfirmDeleteCancel,
                onDeleteLocation,

                onReset,

                user,
                isDataAnalyst,
            };
        },
    });
</script>
