<template>
    <a
        class="sn-filelink hover:cursor-pointer underline"
        :onClick="onLinkClick"
    >
        {{ params.value || '--' }}
    </a>
</template>
<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FileLink',
        props: {
            params: {
                /** @type {V.PropType<AgGrid.ICellRendererParams & { handleLinkClick: (data: any) => void }>} */
                type: Object,
                required: true,
            },
        },
        setup(props) {
            /* Functions */
            const onLinkClick = () => {
                props.params.handleLinkClick(props.params.data);
            };

            return {
                onLinkClick,
            };
        },
    });
</script>

<style>
    .sn-filelink {
        color: #103355;
    }
</style>
