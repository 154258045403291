<template>
    <div class="pb-6">
        <BasicModal
            size="xl"
            :show="photoModal.isOpen"
            @close="onHidePhoto"
        >
            <div class="sn-location-photo">
                <img
                    :src="cleanLocationDetails.photo.url + '?' + photoDateTime"
                />
            </div>
        </BasicModal>
        <BasicModal
            size="xl"
            :show="floorplanModal.isOpen"
            @close="onHideFloorplan"
        >
            <div class="sn-location-photo">
                <img
                    :src="
                        cleanLocationDetails.floorplan.url + '?' + photoDateTime
                    "
                />
            </div>
        </BasicModal>
        <BasicModal
            size="md"
            :show="hierarchyModal.isOpen"
            @close="onEditHierarchyCancel"
        >
            <AssignLocationHierarchyForm
                :form="form"
                :currentHierarchy="dirtyLocationDetails.hierarchy"
                @input="dirtyLocationDetails.onHierarchyInput"
            />
        </BasicModal>
        <LoadingWrapper :isLoading="isSaving">
            <!-- Info section inputs are not dynamically generated as a stop-gap time saver. Rather than
    figuring out how to add the location hierarchy as a custom generated component, for now, Leo has merely
    written out this section by hand. This works but does have the potential to be a maintainence burden in
    the future, if it starts being a problem do not hesistate to change it -->
            <!-- https://formkit.com/essentials/forms -->
            <FormKit
                id="location-details-form"
                type="form"
                v-model="dirtyLocationDetails.resource"
                :actions="false"
                :errors="formErrors"
                :config="{
                    delay: 250,
                    'validation-visibility': 'live',
                }"
                #default="context"
                preserve
            >
                <div class="w-full px-3 border-top">
                    <!-- SEPARATOR -->
                </div>
                <FormSection
                    class="pt-4"
                    title="Location Information"
                >
                    <template #description>
                        <p>
                            eClimateNotebook uses five fields or levels to
                            identify and describe a monitoring space. The
                            <span class="font-bold">Location Hierarchy</span>
                            consists of four levels, with the fifth level the
                            <span class="font-bold">Location Name</span>. These
                            are combined in the
                            <span class="font-bold">Location Display Name</span>
                            seen on graphs, tables, and reports.
                        </p>
                        <p>
                            NARA uses consistent terminology and abbreviations
                            for choices in the four Location Hierarchy levels.
                            <span class="font-bold"
                                >Level 1 - Building. Level 2 -
                                Administration</span
                            >
                            (AO-Archives Office. FRC-Records Center.
                            PLA-Presidential Library Archives. PLM-Presidential
                            Library Museum).
                            <span class="font-bold">Level 3 - Space Type</span>
                            (ST-Storage. WR-Workroom. EX-Exhibition. RR-Research
                            Room).
                            <span class="font-bold"
                                >Level 4 - Monitoring Site</span
                            >
                            (space name/number or a custom identifier). The
                            fifth level
                            <span class="font-bold">Location Name</span> is the
                            specific monitoring location in the space. In
                            storage areas this may start with UL (upper level
                            shelf) or LL (lower level shelf) followed by the
                            minimum HMS or ARCIS information needed to find the
                            datalogger. In workrooms or exhibition areas a site
                            location such as desk, wall, or case could be used.
                        </p>
                    </template>
                    <template #default>
                        <div class="col-span-3">
                            <FormKit
                                type="text"
                                label="Date Range"
                                :value="dirtyLocationDetails.dateRange"
                                :classes="{
                                    outer: isRefreshing
                                        ? 'px-4 sm:px-2 animate-pulse'
                                        : 'px-4 sm:px-2',
                                    inner: 'border-none',
                                }"
                                placeholder="No data present for this location."
                                :help="
                                    dirtyLocationDetails.hasDateRange
                                        ? ''
                                        : 'Upload a dataset to get this information.'
                                "
                                :disabled="true"
                                :ignore="true"
                            />
                            <FormSection
                                class="pt-4"
                                :grid="[
                                    'mt-1',
                                    'grid',
                                    'grid-cols-1',
                                    'gap-x-0',
                                    'sm:grid-cols-3',
                                ]"
                            >
                                <div class="col-span-1 sm:col-span-2">
                                    <FormKit
                                        id="hierarchy-display"
                                        type="text"
                                        :label="'* Location Hierarchy'"
                                        name="path"
                                        :classes="{
                                            outer: isRefreshing
                                                ? 'px-4 sm:px-2 animate-pulse'
                                                : 'px-4 sm:px-2',
                                            inner: isEditing
                                                ? 'w-full border-none hover:cursor-not-allowed'
                                                : '$reset w-full rounded-lg mb-1 focus-within:border-blue-500 border-none',
                                            input: isEditing
                                                ? '$resw-full'
                                                : 'w-full overflow-x-auto',
                                        }"
                                        :delay="150"
                                        :disabled="true"
                                        :ignore="true"
                                    >
                                        <template #input>
                                            <div
                                                class="w-full text-gray-600 rounded-lg border-1 border-gray-200 px-3 py-2 cursor-not-allowed whitespace-nowrap overflow-x-auto"
                                            >
                                                <span
                                                    v-if="
                                                        !!dirtyLocationDetails &&
                                                        dirtyLocationDetails.hasPath
                                                    "
                                                    >{{
                                                        dirtyLocationDetails
                                                            .resource.path
                                                    }}</span
                                                >
                                                <span v-else>{{
                                                    getHierarchyTreeLevels().join(
                                                        ' / '
                                                    )
                                                }}</span>
                                            </div>
                                        </template>
                                    </FormKit>
                                </div>
                                <div
                                    class="col-span-1 flex flex-row justify-center sm:justify-start"
                                >
                                    <VariantButton
                                        v-if="isEditing"
                                        label="Edit Location Hierarchy"
                                        @click="onEditHierarchy"
                                        :disabled="isSaving"
                                    />
                                </div>
                            </FormSection>
                        </div>
                        <FormKit
                            id="name"
                            type="text"
                            label="* Location Name"
                            name="name"
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse col-span-2'
                                    : 'px-4 sm:px-2 col-span-2',
                                inner: isEditing
                                    ? '$reset w-full border-none'
                                    : '$reset w-full border-none',
                                input: isEditing
                                    ? '$reset w-full border-1 border-gray-500 text-black rounded-lg overflow-x-auto'
                                    : '$reset w-full border-none px-3 cursor-not-allowed hover:text-gray-600 overflow-x-auto',
                            }"
                            :placeholder="
                                isEditing
                                    ? 'Enter Location name here...'
                                    : 'No Location name provided.'
                            "
                            :disabled="!isEditing || isBusy"
                            validation="required|length:1,"
                            valdiation-name="Location Name"
                            validation-visibility="blur"
                        />
                    </template>
                </FormSection>
                <FormSection class="pt-4">
                    <FormKit
                        v-for="(metadata, index) in sections.dataLogger"
                        :key="`info-section-${index}`"
                        :type="metadata.type"
                        :label="metadata.label"
                        :name="metadata.name"
                        :classes="{
                            outer: isRefreshing
                                ? 'px-4 sm:px-2 animate-pulse w-full'
                                : 'px-4 sm:px-2 w-full',
                        }"
                        :placeholder="
                            isEditing
                                ? 'Enter details here...'
                                : 'No information provided.'
                        "
                        :disabled="!isEditing || isBusy"
                        :innerClass="isEditing ? '' : 'border-none'"
                    />
                    <FormKit
                        id="nara-standard"
                        type="select"
                        name="standard"
                        :classes="{
                            outer: isRefreshing
                                ? 'px-4 sm:px-2 animate-pulse'
                                : 'px-4 sm:px-2',
                            input: isEditing
                                ? ''
                                : 'appearance-none not-isEditing px-3 py-2 h-10 text-base text-gray-700 border-none rounded-lg hover:cursor-not-allowed',
                        }"
                        v-model="dirtyLocationDetails.standard"
                        :options="naraStandardOptions"
                        @input="onNARAStandardInput"
                        :disabled="!isEditing || isBusy"
                        :ignore="true"
                    >
                        <template #label>
                            <div class="block mb-1 font-bold text-sm">
                                <div class="inline font-bold">
                                    NARA Standard
                                </div>
                                <TeleportTrigger
                                    class="inline"
                                    @open="standardsModal.open"
                                />
                            </div>
                        </template>
                    </FormKit>
                    <FormKit
                        id="timezone"
                        type="select"
                        label="* Time Zone"
                        name="timezone"
                        v-model="dirtyLocationDetails.resource.timezone"
                        :classes="{
                            outer: isRefreshing
                                ? 'px-4 sm:px-2 animate-pulse'
                                : 'px-4 sm:px-2',
                            input: isEditing
                                ? ''
                                : 'appearance-none not-isEditing px-3 py-2 h-10 text-base text-gray-700 border-none rounded-lg hover:cursor-not-allowed',
                        }"
                        :options="timezoneOptions"
                        @input="onTimezoneInput"
                        :disabled="!isEditing || isBusy"
                        :validation="[
                            ['required'],
                            ['*+not', 'placeholder', ...legacyTimezones],
                        ]"
                        valdiation-name="Timezone"
                        validation-visibility="blur"
                    />
                    <ViewNARAStandardsModal
                        :show="standardsModal.isOpen.value"
                        @close="standardsModal.close"
                    />
                </FormSection>
                <div class="pt-4 grid grid-cols-3">
                    <span>
                        <FormKit
                            id="photo"
                            type="file"
                            label="Photo"
                            accept=".png,.jpeg,.jpg"
                            :help="
                                !!dirtyLocationDetails.photo.remove
                                    ? 'Cannot upload file while removing existing file.'
                                    : 'Supported file types: .png, .jpg, .jpeg'
                            "
                            name="photo"
                            :classes="{
                                //input: '$reset px-0',
                                fileList: 'w-full',
                                fileItem: 'w-full',
                                fileName: 'hidden',
                                outer: 'px-4',
                                input: isEditing
                                    ? '$reset px-0 pb-1'
                                    : 'hidden',
                                help: isEditing ? '' : 'hidden',
                                removeFiles:
                                    isEditing &&
                                    dirtyLocationDetails.hasPhotoFileSelected
                                        ? 'sn-photo-link underline'
                                        : 'hidden',
                                noFiles: 'hidden',
                            }"
                            v-model="dirtyLocationDetails.photo.value"
                            @input="onFileInput"
                            :disabled="
                                !isEditing ||
                                isBusy ||
                                !!dirtyLocationDetails.photo.remove
                            "
                            :ignore="true"
                        >
                            <template #label>
                                <span class="block mb-1 font-bold text-sm"
                                    >Photo
                                </span>
                                <div
                                    v-if="!isEditing"
                                    class="w-full h-10 px-3 py-2 rounded-lg"
                                >
                                    <a
                                        class="sn-photo-link hover:cursor-pointer underline"
                                        v-if="cleanLocationDetails.hasPhotoURL"
                                        href="#"
                                        @click="onShowPhoto"
                                    >
                                        (View)</a
                                    >
                                    <span v-else> No photo available. </span>
                                </div>
                            </template>
                        </FormKit>
                        <FormKit
                            v-if="isEditing && cleanLocationDetails.hasPhotoURL"
                            id="remove-photo"
                            type="checkbox"
                            label="Remove Existing?"
                            name="remove-photo"
                            help="Check to delete existing photo on save."
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse'
                                    : 'px-4 sm:px-2',
                                wrapper:
                                    '$reset formkit-wrapper flex flex-row h-8 space-x-2',
                                label: '$reset formkit-label text-sm h-8',
                                inner: '$reset formkit-inner text-sm h-8',
                            }"
                            v-model="dirtyLocationDetails.photo.remove"
                            @input="onFileRemoveInput"
                            :ignore="true"
                        />
                    </span>
                    <span>
                        <FormKit
                            id="floorplan"
                            type="file"
                            label="Floor Plan"
                            accept=".png,.jpeg,.jpg"
                            :help="
                                !!dirtyLocationDetails.floorplan.remove
                                    ? 'Cannot upload file while removing existing file.'
                                    : 'Supported file types: .png, .jpg, .jpeg'
                            "
                            name="floorplan"
                            v-model="dirtyLocationDetails.floorplan.value"
                            :classes="{
                                fileList: 'w-full',
                                fileItem: 'w-full',
                                fileName: 'hidden',
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse'
                                    : 'px-4 sm:px-2',
                                input: isEditing
                                    ? '$reset px-0 pb-1'
                                    : 'hidden',
                                help: isEditing ? '' : 'hidden',
                                removeFiles:
                                    isEditing &&
                                    dirtyLocationDetails.hasFloorplanFileSelected
                                        ? 'sn-photo-link underline'
                                        : 'hidden',
                                noFiles: 'hidden',
                            }"
                            @input="onFileInput"
                            :disabled="
                                !isEditing ||
                                isBusy ||
                                !!dirtyLocationDetails.floorplan.remove
                            "
                            :ignore="true"
                        >
                            <template #label>
                                <span class="block mb-1 font-bold text-sm"
                                    >Floor Plan
                                </span>
                                <div
                                    v-if="!isEditing"
                                    class="w-full h-10 px-3 py-2 rounded-lg"
                                >
                                    <a
                                        class="sn-photo-link hover:cursor-pointer underline"
                                        v-if="
                                            cleanLocationDetails.hasFloorplanURL
                                        "
                                        href="#"
                                        @click="onShowFloorplan"
                                        >(View)</a
                                    >
                                    <span v-else>
                                        No floor plan available.
                                    </span>
                                </div>
                            </template>
                        </FormKit>
                        <FormKit
                            v-if="
                                isEditing &&
                                cleanLocationDetails.hasFloorplanURL
                            "
                            id="remove-floorplan"
                            type="checkbox"
                            label="Remove Existing?"
                            name="remove-floorplan"
                            help="Check to delete existing floor plans on save."
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse'
                                    : 'px-4 sm:px-2',
                                wrapper:
                                    '$reset formkit-wrapper flex flex-row h-8 space-x-2',
                                label: '$reset formkit-label text-sm h-8',
                                inner: '$reset formkit-inner text-sm h-8',
                            }"
                            v-model="dirtyLocationDetails.floorplan.remove"
                            @input="onFileRemoveInput"
                            :disabled="isBusy"
                            :ignore="true"
                        />
                    </span>
                </div>
                <hr />
                <FormSection
                    class="pt-4"
                    title="Administrative Information"
                >
                    <FormKit
                        v-for="(metadata, index) in sections.admin"
                        :key="`info-section-${index}`"
                        :type="metadata.type"
                        :label="metadata.label"
                        :name="metadata.name"
                        :classes="{
                            outer: isRefreshing
                                ? 'px-4 sm:px-2 animate-pulse w-full'
                                : 'px-4 sm:px-2 w-full',
                        }"
                        :placeholder="
                            isEditing
                                ? 'Enter details here...'
                                : 'No information provided.'
                        "
                        :disabled="!isEditing || isBusy"
                        :innerClass="isEditing ? '' : 'border-none'"
                    />
                </FormSection>
                <FormSection
                    class="pt-4"
                    title="Mechanical System Information"
                >
                    <FormKit
                        v-for="(metadata, index) in sections.mechanical"
                        :key="`info-section-${index}`"
                        :type="metadata.type"
                        :label="metadata.label"
                        :name="metadata.name"
                        :classes="{
                            outer: isRefreshing
                                ? 'px-4 sm:px-2 animate-pulse w-full'
                                : 'px-4 sm:px-2 w-full',
                        }"
                        :placeholder="
                            isEditing
                                ? 'Enter details here...'
                                : 'No information provided.'
                        "
                        :disabled="!isEditing || isBusy"
                        :innerClass="isEditing ? '' : 'border-none'"
                    />
                </FormSection>
                <section v-if="isEditing">
                    <p class="text-gray-400 text-sm">
                        * indicates a required field
                    </p>
                    <FormSubmitCancel
                        :onSave="onSaveLocation"
                        :onCancel="onEditCancel"
                        :allowSave="context.state.valid && checkIfDirty()"
                        outerClass="px-4"
                    />
                </section>
                <!-- DEBUG INFORMATION -->
                <div
                    v-if="!!debug"
                    class="bg-gray-200 m-2 p-2 indent-0.5"
                >
                    <pre
                        class="w-full text-xs subpixel-antialiased whitespace-pre-wrap break-words"
                        >{{ getDebugInfo(context) }}
        </pre
                    >
                </div>
            </FormKit>
        </LoadingWrapper>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, ref, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import TeleportTrigger from '@/components/modals/TeleportTrigger.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';
    //import BasicAccordion from '@/components/BasicAccordion.vue';
    import BasicModal from '@/components/BasicModal.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import AssignLocationHierarchyForm from '~DataManager/components/form/AssignLocationHierarchyForm.vue';
    import ViewNARAStandardsModal from '~Analysis/components/modals/ViewNARAStandardsModal.vue';

    // <!-- COMPOSABLES -->
    import { useNARAStandardManager } from '~NARAStandards/hooks/useNARAStandardManager';
    import { useFormkitDebugger } from '@/utils/FormKitDebugger';
    import { LocationFormConfig } from '~DataManager/hooks/useLocationForm';
    import { getLegacyTimezones } from '~DataManager/hooks/useTimezoneOptions';
    import { useNARAStandardOptions } from '~DataManager/hooks/useNARAStandardOptions';
    import { useModalToggle } from '@/hooks/modals';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'EditLocationForm',
        props: {
            /** Form configuration. */
            form: {
                /** @type {V.PropType<LocationFormConfig>} */
                type: Object,
                required: true,
            },
            /** Debug mode. */
            debug: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        components: {
            FormSection,
            FormSubmitCancel,
            //BasicAccordion,
            BasicModal,
            LoadingWrapper,
            VariantButton,
            AssignLocationHierarchyForm,
            ViewNARAStandardsModal,
            TeleportTrigger,
        },
        setup(props, context) {
            const { form } = toRefs(props);

            // ==== COMPOSABLES ====
            const manager = useNARAStandardManager();
            const { grid, data, actions } = manager;

            //const { defaultColDef } = grid;
            const defaultColDef = {
                resizable: true,
                sortable: true,
                suppressMovable: true,
                suppressMenu: true,
                minWidth: 150,
                flex: 1,
                lockPosition: true,
                cellClass: 'break-normal',
            };
            const { rowData, viewColDefs } = data;

            /** Provide the modal open/close API. */
            const { modal: standardsModal } = useModalToggle();

            // ==== EVENTS ====
            const { onGridReady, onColumnResized } = grid;
            const { open, close } = actions;

            // STATE
            const {
                cleanLocationDetails,
                dirtyLocationDetails,
                floorplanModal,
                hierarchyModal,
                photoModal,
                nodes,
                photoDateTime,
            } = form.value.state;

            // PROPERTIES
            const {
                isBusy,
                isDeleting,
                isEditing,
                isEnabled,
                isRefreshing,
                isSaving,

                hasErrors,
                formErrors,
                hierarchyErrors,
            } = form.value.properties;

            // OPTIONS
            const { sections, timezoneOptions } = form.value.constants;
            const legacyTimezones = getLegacyTimezones();

            const { getOptions } = useNARAStandardOptions();
            const naraStandardOptions = getOptions();

            // HANDLERS
            const {
                onExit,
                onEditHierarchy,
                onEditHierarchyCancel,
                onShowPhoto,
                onHidePhoto,
                onShowFloorplan,
                onHideFloorplan,
                onEditCancel,
                onSave: onSaveLocation,
                onFileInput,
                onFileRemoveInput,
                onTextInput,
                onTimezoneInput,
                onNARAStandardInput,
            } = form.value.handlers;

            // METHODS
            const { getHierarchyTreeLevels, checkIfDirty } = form.value.methods;

            // DEBUG
            const { getDebugInfo } = useFormkitDebugger(
                ref('location-details-form'),
                cleanLocationDetails,
                dirtyLocationDetails
            );

            onMounted(() => {
                checkIfDirty();
            });

            return {
                floorplanModal,
                hierarchyModal,
                standardsModal,
                photoModal,
                nodes,

                cleanLocationDetails,
                dirtyLocationDetails,
                photoDateTime,

                sections,
                timezoneOptions,
                legacyTimezones,

                isBusy,
                isDeleting,
                isEditing,
                isEnabled,
                isRefreshing,
                isSaving,

                hasErrors,
                formErrors,
                hierarchyErrors,
                onEditHierarchy,
                onEditHierarchyCancel,
                onShowPhoto,
                onHidePhoto,
                onShowFloorplan,
                onHideFloorplan,

                onEditCancel,
                onSaveLocation,

                onFileInput,
                onFileRemoveInput,
                onTextInput,
                onTimezoneInput,
                onNARAStandardInput,

                open,
                close,
                naraStandardOptions,
                manager,
                defaultColDef,
                rowData,
                viewColDefs,
                onGridReady,
                onColumnResized,

                getHierarchyTreeLevels,
                checkIfDirty,

                getDebugInfo,
            };
        },
    });
</script>

<style scoped>
    .sn-hide-span span {
        display: none;
    }

    .sn-photo-link {
        color: #103355;
    }

    .sn-location-photo {
        min-width: 600px;
        min-height: 400px;
    }

    .sn-location-photo:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        border: 2px solid #092640;
        border-color: transparent #092640 transparent #092640;
        border-radius: 50%;
        animation: loader 1s linear infinite;
        z-index: -2;
    }

    @keyframes loader {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
</style>
