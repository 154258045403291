<template>
    <div
        class="flex flex-nowrap flex-row h-full w-full align-middle items-center justify-around"
    >
        <BaseTooltip
            target="#tooltip-target"
            text="Download Datasets Batch"
            :textSize="'text-md'"
            :width="120"
        >
            <template #icon>
                <VariantButton
                    variant="icon"
                    alt="Download Dataset Batch"
                    @click.prevent="params.handleExport($event, params.node.id)"
                >
                    <DocumentDownloadIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
        <BaseTooltip
            target="#tooltip-target"
            text="Delete Dataset Batch"
            :textSize="'text-md'"
            :width="120"
        >
            <template #icon>
                <VariantButton
                    v-if="isDataAnalyst === false"
                    variant="danger-icon"
                    alt="Delete Dataset Batch"
                    @click.prevent="params.handleDelete($event, params.node.id)"
                >
                    <TrashIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import { DocumentDownloadIcon, TrashIcon } from '@heroicons/vue/outline';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';

    // <!-- TYPES -->

    /**
     * @typedef {AgGrid.ICellRendererParams & { handleExport: Function, handleDelete: Function }} DatasetBatchesTableIconsParams
     */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DatasetBatchesTableIcons',
        components: {
            DocumentDownloadIcon,
            TrashIcon,
            VariantButton,
            BaseTooltip,
        },
        props: {
            params: {
                /** @type {V.PropType<DatasetBatchesTableIconsParams>} */
                type: Object,
            },
        },
        setup(props, context) {
            // ==== COMPOSE ====
            const store = useStore();

            const user = computed(() => {
                return store.state.users.me;
            });

            /** @type {V.ComputedRef<Boolean>} */
            const isDataAnalyst = computed(
                () => user.value?.role === 'data-analyst'
            );

            return {
                user,
                isDataAnalyst,
            };
        },
    });
</script>

<style lang="scss">
    div.ag-theme-alpine.ag-cell {
        padding: 0px;
        margin: auto 1px;
    }
</style>
