<template>
    <ConfirmDeleteModal
        title="Delete Dataset Batch"
        confirmationText="Are you sure you want to delete this dataset batch?"
        :open="confirmDeleteModalOpen"
        @delete="onDeleteDataset"
        @cancel="onConfirmDeleteCancel"
        @close="onConfirmDeleteCancel"
    />
    <!-- <button @click="forceRefresh">TEST REFRESH</button> -->
    <LoadingWrapper :isLoading="isLoading || isBusy">
        <SectionHeader class="mb-6">Upload History</SectionHeader>
        <AgGridVue
            class="ag-theme-alpine"
            :pagination="true"
            :paginationPageSize="25"
            :columnDefs="colDefs"
            :rowData="state.datasetBatches"
            domLayout="autoHeight"
            :defaultColDef="defaultColDef"
            :getRowNodeId="useDatasetBatchIdAsId"
            @grid-ready="onGridReady"
            @column-resized="onColumnResized"
            overlayNoRowsTemplate="No datasets available."
        ></AgGridVue>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue';
    import { promiseTimeout } from '@vueuse/shared';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import SectionHeader from '@/components/SectionHeader.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import DatasetBatchesTableIcons from '~DataManager/components/cell/DatasetBatchesTableIcons.vue';
    import FileLink from '~DataManager/components/cell/FileLink.vue';

    // <!-- UTILITIES -->
    import isNil from 'lodash-es/isNil';
    import { saveAs } from 'file-saver';
    import { DateTimeISO } from '@/utils/datetime';
    import { formatISO } from 'date-fns';

    // <!-- COMPOSABLES -->
    import { LocationFormConfig } from '~DataManager/hooks/useLocationForm';
    import { useAxios as axios } from '@plugins/axios';
    import useAgGridVue from '@/hooks/useAgGridVue';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DatasetBatchesTable',
        props: {
            /** Form configuration. */
            form: {
                type: LocationFormConfig,
                required: true,
            },

            isBusy: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            ConfirmDeleteModal,
            LoadingWrapper,
            SectionHeader,
            AgGridVue,
        },

        setup(props) {
            const { form, isBusy } = toRefs(props);

            // STORE
            const store = form.value.cache.api.store;

            // PROPS (FORM)
            const currentLocation = form.value.properties.sourceLocation;

            // STATE
            const state = reactive({
                datasetBatches: [],
                datasetBatchIdToDelete: null,
            });

            // METHODS
            const getAccount = () => store.state.accounts.account;
            const onRefresh = form.value.handlers.onRefresh;

            /* Data */
            let isLoading = ref(false);
            let confirmDeleteModalOpen = ref(false);

            /**
             * @type {AgGrid.ValueFormatterFunc}
             * Format the ISO string into just its date component.
             */
            const useDateComponentFormat = (params) => {
                try {
                    const { value = '' } = params;
                    if (!isNil(value) && value !== '') {
                        const date = DateTimeISO.parse(value);
                        const formatted = formatISO(date, {
                            format: 'extended',
                            representation: 'date',
                        });
                        // If format is successful, exists.
                        return formatted;
                    }
                } catch (error) {
                    console.warn(error);
                }
                // If format is unsuccessful, return default value.
                return `No date provided.`;
            };

            const colDefs = [
                {
                    headerName: '',
                    field: 'id',
                    cellRendererFramework: DatasetBatchesTableIcons,
                    lockPosition: true,
                    maxWidth: 100,
                    filter: false,
                    floatingFilter: false,
                    cellRendererParams: {
                        handleExport: (event, id) => {
                            handleDatasetBatchExport(id);
                        },
                        handleDelete: (event, id) => {
                            handleDeleteDatasetBatch(id);
                        },
                    },
                },
                {
                    headerName: 'File Name',
                    field: 'filename',
                    cellRendererFramework: FileLink,
                    filter: false,
                    floatingFilter: false,
                    cellRendererParams: {
                        handleLinkClick: (rowData) => {
                            onDatasetBatchFileDownload(
                                rowData.id,
                                rowData.filename
                            );
                        },
                    },
                },

                {
                    headerName: 'Uploaded',
                    field: 'created_at',
                    valueFormatter: useDateComponentFormat,
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                    sort: 'desc',
                },
                {
                    headerName: 'Data Start',
                    field: 'data_start',
                    valueFormatter: useDateComponentFormat,
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                },
                {
                    headerName: 'Data End',
                    field: 'data_end',
                    valueFormatter: useDateComponentFormat,
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                },
                {
                    headerName: '# of Data Points',
                    field: 'number_of_points',
                    /** @param {AgGrid.ICellRendererParams} params */
                    valueGetter: (params) => {
                        const points = params.data?.number_of_points;
                        const exists = !!points;
                        return exists ? points : 'Not Ingested';
                    },
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                },
            ];

            /* Functions */
            const onDatasetBatchFileDownload = async (id, filename) => {
                try {
                    // TODO: Refactor into API.
                    const account = getAccount().id;
                    const location = currentLocation.value.id;
                    const response = await axios().get(
                        `accounts/${account}/locations/${location}/datasets/${id}/file`,
                        { responseType: 'blob' }
                    );
                    saveAs(response.data, filename);
                } catch (err) {
                    console.warn('Error downloading dataset batch file');
                }
            };
            const getDatasetBatches = async () => {
                isLoading.value = true;
                // TODO: Refactor into API.
                const account = getAccount().id;
                const location = currentLocation.value.id;
                const response = await axios().get(
                    // Data returned from API does not match expected name
                    `accounts/${account}/locations/${location}/datasets`
                );
                state.datasetBatches = response.data.data;
                isLoading.value = false;
            };

            const { onGridReady, onColumnResized, defaultColDef } =
                useAgGridVue();
            const useDatasetBatchIdAsId = (data) => {
                return data.id;
            };

            const handleDeleteDatasetBatch = async (datasetBatchId) => {
                confirmDeleteModalOpen.value = true;
                state.datasetBatchIdToDelete = datasetBatchId;
            };

            const getDatasetBatchFilenameById = (datasetBatchId) => {
                const targetDatasetBatch = state.datasetBatches.find(
                    (datasetBatch) => {
                        return datasetBatch.id == datasetBatchId;
                    }
                );
                return targetDatasetBatch.filename
                    ? targetDatasetBatch.filename
                    : 'UNKNOWN';
            };

            const handleDatasetBatchExport = async (datasetBatchId) => {
                // TODO: Refactor API.
                const account = getAccount().id;
                const location = currentLocation.value.id;
                const response = await axios().get(
                    `accounts/${account}/locations/${location}/datasets/${datasetBatchId}/file`,
                    { responseType: 'blob' }
                );
                const filename = getDatasetBatchFilenameById(datasetBatchId);
                saveAs(response.data, filename);
            };

            const onDeleteDataset = async () => {
                confirmDeleteModalOpen.value = false;
                isLoading.value = true;
                // TODO: Refactor API.
                const account = getAccount().id;
                const location = currentLocation.value.id;
                const response = await axios().delete(
                    `accounts/${account}/locations/${location}/datasets/${state.datasetBatchIdToDelete}`
                );
                if (response.status == 500) {
                    isLoading.value = false;
                    console.error('Failed to delete dataset batch');
                }
                isLoading.value = false;
                await getDatasetBatches();
                await promiseTimeout(5000);
                await onRefresh();
            };

            const onConfirmDeleteCancel = () => {
                confirmDeleteModalOpen.value = false;
            };

            const forceRefresh = () => {
                onRefresh();
            };

            /* LifeCycle */
            onMounted(async () => {
                await getDatasetBatches();
            });

            return {
                state,
                useDatasetBatchIdAsId,
                getDatasetBatches,
                colDefs,
                onGridReady,
                onColumnResized,
                defaultColDef,
                handleDeleteDatasetBatch,
                isLoading,
                confirmDeleteModalOpen,
                onDeleteDataset,
                onConfirmDeleteCancel,
                forceRefresh,
            };
        },
    });
</script>

<style lang="scss" scoped></style>
